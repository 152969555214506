<template>
  <div class="main">
    <router-link to="/shops">
      <a-button
        class="p-0 mb-3"
        type="link"
        icon="arrow-left"
        size="large">
        {{ $t('back') }} {{ $t('to') }} {{ $t('msg.platform.name') }}
      </a-button>
    </router-link>
    <h2>{{ $t('msg.platform.new.title') }}</h2>
    <p class="my-4">{{ $t('msg.platform.new.subtitle') }}</p>
    <a-list
      :grid="{ gutter: 16, xs: 1, sm: 2, xl: 2 }"
      :data-source="results">
      <a-list-item
        slot="renderItem"
        slot-scope="item">
        <router-link :to="`/shops/connect?type=${item.code}`">
          <a-card hoverable>
            <template slot="actions">
              <a-button
                icon="plus-circle"
                type="link">
                {{ $t('connect') }}
              </a-button>
            </template>
            <a-card-meta
              :title="item.name"
              :description="item.description">
              <a-avatar
                slot="avatar"
                shape="square"
                :size="64"
                :src="`/resources/images/platforms/${item.code}.png`" />
            </a-card-meta>
          </a-card>
        </router-link>
      </a-list-item>
    </a-list>
  </div>
</template>
<script>
import lms from '@/api/lms'
export default {
  name: 'PlatformAvailable',
  data() {
    return {}
  },
  computed: {
    results() {
      return this.$store.getters['platforms/AVAILABLE']
    },
  },
  mounted() {
    this.fetch()
  },
  methods: {
    fetch() {
      return lms.platform.fetchAvailable()
        .then(r => this.$store.commit('platforms/SET_AVAILABLE', r.data))
    },
  },
}
</script>
